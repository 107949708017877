import React from 'react';

function About() {
  return (
    <div>
      <h2>About Us</h2>
      <p>About information goes here...</p>
    </div>
  );
}

export default About;


import React from 'react';

function Header() {
  const imagePath = '/DZcoverphoto.jpeg'; // Specify the path to your image

  return (
    <header className="header">
      <img src={imagePath} alt="Logo" className="logo" /> {/* Use the image path directly */}
      <h1>MOPV</h1>
      {/* Add navigation or any other content you want in the header */}
    </header>
  );
}

export default Header;

// Footer.js
import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      {/* Add footer content, such as links, social media icons, etc. */}
      <p>
        Copyright � 2022 DataZeneral | All Rights Reserved | Powered By Emmela | Talk to us: 
        <a href="mailto:raja@datazeneral.com">raja@datazeneral.com</a> | 
        +91 9632306600 | Remote First: Working from India
      </p>
    </footer>
  );
}

export default Footer;

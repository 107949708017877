// Section3.js
import React from 'react';

function Section3() {
  const sectionStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '20vh',
    backgroundColor: 'white',
    color: 'white', // Adjust width as desired
  };

  return (
    <div style={sectionStyles}>
    </div>
  );
}

export default Section3;


import React, { useState } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Section1 from './components/Section1';
// import Section2 from './components/Section2';
import Section3 from './components/Section3';
import Contact from './components/Contact'; // Import Contact component
import About from './components/About'; // Import About component
import './App.css';

function App() {
  return (
    <div className="app-container">
      <Header />
      <Section1 />
      {/* <Section2 /> */}
      <div className="nav-buttons-container">
        <ContactButton />
        <AboutButton />
      </div>
      <Section3 />
      <Footer />
    </div>
  );
}

function ContactButton() {
  const [showContactDetails, setShowContactDetails] = useState(false);

  const toggleContactDetails = () => {
    setShowContactDetails(!showContactDetails);
  };

  return (
    <div className="nav-button">
      <button onClick={toggleContactDetails}>Contact</button>
      {showContactDetails && <Contact />}
    </div>
  );
}

function AboutButton() {
  const [showAboutDetails, setShowAboutDetails] = useState(false);

  const toggleAboutDetails = () => {
    setShowAboutDetails(!showAboutDetails);
  };

  return (
    <div className="nav-button">
      <button onClick={toggleAboutDetails}>About</button>
      {showAboutDetails && <About />}
    </div>
  );
}

export default App;

